<template>
    <div class="StaffManagement" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="机构组名称">
                            <el-select
                                filterable
                                v-model="form.deptGroupCode"
                                @change="handleChangeGroup"
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="配送员">
                            <el-input placeholder="姓名/手机号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="状态">
                            <el-select placeholder="类型" v-model="form.state">
                                <el-option label="请选择" value="" />
                                <el-option label="停用" value="0" />
                                <el-option label="启用" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.system.staffManagement.open')"
                >查询
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="姓名" prop="realName" />
                <el-table-column label="手机号" prop="mobile" />
                <el-table-column label="所属机构组" prop="staffGroups">
                    <template slot-scope="scope">
                        <span v-for="(item, index) in scope.row.staffGroups" :key="index"
                            >{{ item.deptGroupName }}
                            <span v-if="scope.row.staffGroups.length > 1 && index != scope.row.staffGroups.length - 1">
                                ,
                            </span></span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="归属机构" prop="deptName">
                    <template slot-scope="scope">
                        <span v-for="(item, index) in scope.row.staffDepts" :key="index"
                            >{{ item.deptName
                            }}<span v-if="scope.row.staffDepts.length > 1 && index != scope.row.staffDepts.length - 1">
                                ,
                            </span></span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="是否门店员工" prop="deptStaffFlag">
                    <template slot-scope="scope">
                        <span v-if="scope.row.deptStaffFlag == 0">不是</span>
                        <span v-else-if="scope.row.deptStaffFlag == 1">是</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 0">停用</span>
                        <span v-else-if="scope.row.state == 1">启用</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="80"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope" style="text-align: center">
                        <el-button
                            size="small"
                            type="primary"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.staffManagement.review') && scope.row.state == 0"
                            >启用
                        </el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.staffManagement.review') && scope.row.state == 1"
                            >停用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import UrlUtils from 'js/UrlUtils';

export default {
    mixins: [CheckTableShowColumn],
    name: 'StaffManagement',
    data() {
        return {
            form: {
                deptGroupCode: '',
                deptCode: '',
                state: '',
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
                page: '/system/delivery/staff/page',
                delete: '/system/role/delete',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            loadingDeptGroupFlag: true,
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
            },
        };
    },
    mounted() {
        this.handleQuery();
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.form.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        //停用0 启用1
        handleEdit(row) {
            if (row.state == 1) {
                this.isZeroMethod(row);
            } else if (row.state == 0) {
                this.isOneMethod(row);
            }
        },
        isZeroMethod(row) {
            this.$confirm(
                '确认停用该配送员吗？停用后配送员将不能进行接单发货且将已接单的订单，全部取消接单。',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    UrlUtils.PostRemote(
                        this,
                        `/system/delivery/staff/updateSate?id=${row.id}&state=0`,
                        this.form,
                        null,
                        (data) => {
                            this.$message({
                                type: 'success',
                                message: '停用成功!',
                            });
                            this.handleQuery();
                        }
                    );
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    });
                });
        },
        isOneMethod(row) {
            this.$confirm('确认启用该配送员吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    UrlUtils.PostRemote(
                        this,
                        `/system/delivery/staff/updateSate?id=${row.id}&state=1`,
                        this.form,
                        null,
                        (data) => {
                            this.$message({
                                type: 'success',
                                message: '启用成功!',
                            });
                            this.handleQuery();
                        }
                    );
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    });
                });
        },
    },
};
</script>

<style scoped>
.StaffManagement .el-form-item {
    width: 100%;
    margin-bottom: 0;
}
</style>
